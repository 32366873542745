import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "author": "Noah Wong",
  "authorDescription": "hi",
  "date": "2007-04-03 UTC -8",
  "length": null,
  "location": "Celakovice, Czech Republic",
  "path": "/prague-study-abroad/",
  "tags": ["travel", "prague"],
  "title": "Noah in Prague"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.11111111111111%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwAE/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAABHVnQWWP/xAAZEAEBAQADAAAAAAAAAAAAAAABAgMAECH/2gAIAQEAAQUCiyS9B5pFD60Zr1//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAdEAEAAgICAwAAAAAAAAAAAAABABECIRAiMUGh/9oACAEBAAY/Ajre5Q7y+QMb8RRCyeuP/8QAGxABAAMAAwEAAAAAAAAAAAAAAQARITFBYVH/2gAIAQEAAT8htSzxMPahXf2Ioylp9lZKRinIB6Jlaq+z/9oADAMBAAIAAwAAABBU3//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPxCMf//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPxCtj//EABsQAQACAwEBAAAAAAAAAAAAAAEAESExQVGB/9oACAEBAAE/EDGiqnp78lOSalozB45cKHp2THINwrvZArciFSqdYDAYKuP/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Prague",
          "title": "Prague",
          "src": "/static/61bf2a37edba7b575a8bf278180ccb4b/8e1fc/prague-1.jpg",
          "srcSet": ["/static/61bf2a37edba7b575a8bf278180ccb4b/863e1/prague-1.jpg 225w", "/static/61bf2a37edba7b575a8bf278180ccb4b/20e5d/prague-1.jpg 450w", "/static/61bf2a37edba7b575a8bf278180ccb4b/8e1fc/prague-1.jpg 900w", "/static/61bf2a37edba7b575a8bf278180ccb4b/a2510/prague-1.jpg 1000w"],
          "sizes": "(max-width: 900px) 100vw, 900px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Well, not exactly...about a 30 minute train ride outside the city in a small town called Celakovice. Advertised back in AZ as a quick commute but the time spent waiting for the train and walking to the station makes it a little inconvenient, so I chose not to live in the city with a few of my buddies. Probably a wise decision as classes seem to elude them half the time.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMCBf/EABUBAQEAAAAAAAAAAAAAAAAAAAED/9oADAMBAAIQAxAAAAHnztCdNgf/xAAaEAEAAgMBAAAAAAAAAAAAAAABAAMCERMU/9oACAEBAAEFAvOb4woULVnVDG/IP//EABYRAQEBAAAAAAAAAAAAAAAAAAAhIv/aAAgBAwEBPwGMv//EABYRAQEBAAAAAAAAAAAAAAAAAAAhIv/aAAgBAgEBPwGtP//EAB0QAAEEAgMAAAAAAAAAAAAAAAEAAhARISJBcYH/2gAIAQEABj8CO3NBDYZh59Te7j//xAAbEAEAAgIDAAAAAAAAAAAAAAABABEhMUFhkf/aAAgBAQABPyFEGuxjLBpuKqZcBI4oXp0zCOPSUYn/2gAMAwEAAgADAAAAENAv/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAEDAQE/EEgYv//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAgEBPxAau7//xAAeEAEAAgEEAwAAAAAAAAAAAAABABEhMUFhwXGx4f/aAAgBAQABPxDDn2lirhwcx2ATvChz4xATYg6u5fcp4rxUOYGe0R9hViivRP/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Charles Bridge",
          "title": "Charles Bridge",
          "src": "/static/5714718b1d1f8446e69d5ee5ffb0c6b7/8e1fc/prague-2.jpg",
          "srcSet": ["/static/5714718b1d1f8446e69d5ee5ffb0c6b7/863e1/prague-2.jpg 225w", "/static/5714718b1d1f8446e69d5ee5ffb0c6b7/20e5d/prague-2.jpg 450w", "/static/5714718b1d1f8446e69d5ee5ffb0c6b7/8e1fc/prague-2.jpg 900w", "/static/5714718b1d1f8446e69d5ee5ffb0c6b7/a2510/prague-2.jpg 1000w"],
          "sizes": "(max-width: 900px) 100vw, 900px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Living in a small quiet town which is inundated with mullets and gold chains, however, the depression era style food is quite satisfying - plenty of meat and potatoes. Not quite as cheap as China but can still get a cold frosty one for less than a buck - pivou anyone?`}</p>
    <p>{`I haven't quite mastered the Czech language yet, still struggling with hello and thank you, it's a mystery how I ever learned Chinese, and surprisingly the first second language I have spoken over here was Chinese. There is a small Sichuan-style restaurant about 5 minutes away from my place. Quite authentic and similar to the cuisine I have been craving since leaving Shanghai. No matter where you are in the world, far reaches of Eastern Europe or the deep jungles of Cambodia, there's one thing you can always count on finding - A Chinese restaurant.`}</p>
    <p>{`Weather is very similar to the NW in the spring. Mostly sunny and warm during the day, shorts and t-shirts optional although it can get pretty cold at night. Rained for the first time last night. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "604px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.11111111111111%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAgQA/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgAB/9oADAMBAAIQAxAAAAGcmgp6DWf/xAAbEAACAgMBAAAAAAAAAAAAAAABEQACAxITIf/aAAgBAQABBQLYI2MU8ZQxdLT/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwGH/8QAGBAAAgMAAAAAAAAAAAAAAAAAABASIUH/2gAIAQEABj8CdEtX/8QAGRABAQEBAQEAAAAAAAAAAAAAAREAITFh/9oACAEBAAE/IQmBU+YHLnrUme3Tpeobwi7/2gAMAwEAAgADAAAAEMD/AP/EABYRAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPxChf//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/ECP/xAAcEAEAAwEAAwEAAAAAAAAAAAABABEhMUFhcZH/2gAIAQEAAT8QQ2I3gZiGPoCoHRvyLBCqxWnLlkgkI+d1/bqIAWV1Z//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Prague",
          "title": "Prague",
          "src": "/static/cccc327584bb225b0a89b0f2a52226dc/efa6e/prague-4.jpg",
          "srcSet": ["/static/cccc327584bb225b0a89b0f2a52226dc/863e1/prague-4.jpg 225w", "/static/cccc327584bb225b0a89b0f2a52226dc/20e5d/prague-4.jpg 450w", "/static/cccc327584bb225b0a89b0f2a52226dc/efa6e/prague-4.jpg 604w"],
          "sizes": "(max-width: 604px) 100vw, 604px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <div style={{
      "background": "#333",
      "color": "#fff",
      "fontSize": "1.6rem",
      "margin": "3rem 0",
      "display": "flex",
      "flexDirection": "column",
      "textAlign": "center",
      "padding": "1rem"
    }}>
    <span>Celakovice</span>
    <span className="red">Czech Republic</span>
    </div>
    <div style={{
      "width": "100%"
    }}><iframe width="100%" height="600" src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=celakovice%20cmc+(My%20Business%20Name)&amp;ie=UTF8&amp;t=&amp;z=18&amp;iwloc=B&amp;output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"><a href="https://www.maps.ie/draw-radius-circle-map/">Radius map tool</a></iframe></div><br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      